<template>
  <div class="card">
    <DataTable :value="localJobHistory" dataKey="id">
      <!-- Job Type -->
      <Column field="job_type" header="Job Type" :headerStyle="{width:'13%'}">
        <template #body="slotProps">
          <select
              class="w-full bge-input bge-select rounded"
              v-if="editableRowId === slotProps.data.id"
              v-model="slotProps.data.job_type"
          >
            <option value="SERVICE">SERVICE</option>
            <option value="CALIBRATION">CALIBRATION</option>
            <option value="REPAIR">REPAIR</option>
            <option value="OTHER">OTHER</option>
          </select>
          <span v-else>{{ slotProps.data.job_type }}</span>
        </template>
      </Column>

      <!-- Date -->
      <Column field="job_date" header="Job Date" :headerStyle="{width:'13%'}">
        <template #body="slotProps">
          <Calendar
              v-if="editableRowId === slotProps.data.id"
              appendTo="body"
              dateFormat="dd/mm/yy"
              v-model="slotProps.data.job_date"
              :showIcon="true"
          />
          <span v-else>{{ slotProps.data.job_date ? formatTimestamp(slotProps.data.job_date) : "N/A" }}</span>
        </template>
      </Column>

      <!-- Issue Date -->
      <Column field="cert_issue_date" header="Certificate Issue Date" :headerStyle="{width:'13%'}">
        <template #body="slotProps">
          <Calendar
              v-if="editableRowId === slotProps.data.id"
              appendTo="body"
              dateFormat="dd/mm/yy"
              v-model="slotProps.data.cert_issue_date"
              :showIcon="true"
          />
          <span v-else>{{ slotProps.data.cert_issue_date ? formatTimestamp(slotProps.data.cert_issue_date) : "N/A" }}</span>
        </template>
      </Column>

      <!-- Expiry Date -->
      <Column field="cert_expiry_date" header="Certificate Expiry Date" :headerStyle="{width:'13%'}">
        <template #body="slotProps">
          <Calendar
              v-if="editableRowId === slotProps.data.id"
              appendTo="body"
              dateFormat="dd/mm/yy"
              v-model="slotProps.data.cert_expiry_date"
              :showIcon="true"
          />
          <span v-else>{{ slotProps.data.cert_expiry_date ? formatTimestamp(slotProps.data.cert_expiry_date) : "N/A" }}</span>
        </template>
      </Column>

      <!-- Cert.  Number -->
      <Column field="certificate_number" header="Certificate Number" :headerStyle="{width:'13%'}">
        <template #body="slotProps">
          <InputText v-if="editableRowId === slotProps.data.id" v-model="slotProps.data.certificate_number"/>
          <span v-else>{{ slotProps.data.certificate_number }}</span>
        </template>
      </Column>

      <!-- Job.  Number -->
      <Column field="job_number" header="Job Number" :headerStyle="{width:'13%'}">
        <template #body="slotProps">
          <InputText v-if="editableRowId === slotProps.data.id" v-model="slotProps.data.job_number"/>
          <span v-else>{{ slotProps.data.job_number }}</span>
        </template>
      </Column>

      <!-- Actions -->
      <Column header="" :headerStyle="{width:'13%'}">
        <template #body="slotProps">
          <div class="flex justify-end items-center">
            <!-- Save Button -->
            <AppButton
                v-if="editableRowId === slotProps.data.id"
                color="blue"
                label=""
                :icon="true"
                class="mr-1"
                @click="saveRow(slotProps.data)"
            >
              <template v-slot:icon>
                <Icon iconType="save" />
              </template>
            </AppButton>
            <!-- Cancel Button -->
            <AppButton
                v-if="editableRowId === slotProps.data.id"
                color="gray"
                label=""
                :icon="true"
                class="mr-1"
                @click="cancelEdit(slotProps.data)"
            >
              <template v-slot:icon>
                <Icon iconType="close" />
              </template>
            </AppButton>
            <!-- Edit Button -->
            <AppButton
                v-else
                color="blue"
                label=""
                :icon="true"
                class="mr-1"
                @click="editRow(slotProps.data.id)"
            >
              <template v-slot:icon>
                <Icon iconType="edit" />
              </template>
            </AppButton>
            <!-- Delete Button -->
            <AppButton
                color="red"
                label=""
                :icon="true"
                class="mr-1"
                @click="deleteRow(slotProps.data.id)"
            >
              <template v-slot:icon>
                <Icon iconType="delete" />
              </template>
            </AppButton>
            <!-- Add New Button -->
            <AppButton
                v-if="isLastRow(slotProps.data.id)"
                color="green"
                label=""
                :icon="true"
                class="mr-1"
                @click="addJobRecord"
            >
              <template v-slot:icon>
                <Icon iconType="add-notes" />
              </template>
            </AppButton>
          </div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import AppButton from "@/components/Button.vue";
import Icon from "@/components/Icon.vue";
import moment from "moment";
import _ from "lodash";
import notificationMixin from "@/mixins/notificationMixin";

export default {
  mixins: [notificationMixin],
  components: { AppButton, Icon },
  props: {
    initialJobHistory: {
      type: Array,
      default: () => [],
    },
    equipment_id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      localJobHistory: [], // Copy of initial data
      editableRowId: null, // Tracks the currently editable row
      originalJobData: {}, // Stores the original state of a row for rollback
    };
  },
  mounted() {
    this.localJobHistory = _.cloneDeep(this.initialJobHistory);
    this.checkAndAddInitialJob();
  },
  methods: {
    async addJobRecord() {
      if (this.editableRowId != null) {
        this.notifyWarning("Please save the current job record before adding a new one.");
        return;
      }

      const newJob = {
        id: this.generateGUID(),
        calibration_id: null, // Placeholder for server-assigned ID
        job_type: "SERVICE", // Default job type
        job_date: null,
        cert_issue_date: null,
        cert_expiry_date: null,
        isNew: true,
      };

      this.localJobHistory.push(newJob);
      this.editableRowId = newJob.id;
    },
    async editRow(rowId) {
      const item = this.localJobHistory.find((item) => item.id === rowId);
      if (item) {
        this.originalJobData[rowId] = _.cloneDeep(item); // Backup the row data
        // Convert timestamps to formatted dates for editing
        item.job_date = item.job_date ? this.formatTimestamp(item.job_date) : null;
        item.cert_issue_date = item.cert_issue_date ? this.formatTimestamp(item.cert_issue_date) : null;
        item.cert_expiry_date = item.cert_expiry_date ? this.formatTimestamp(item.cert_expiry_date) : null;
        this.editableRowId = rowId; // Set editable row ID
      }
    },
    async saveRow(item) {
      if (!item.job_type) {
        this.notifyError("Job type are required.");
        return;
      }

      const payload = {
        ...item,
        job_date: this.convertToUnix(item.job_date),
        cert_issue_date: this.convertToUnix(item.cert_issue_date),
        cert_expiry_date: this.convertToUnix(item.cert_expiry_date),
      };

      try {
        if (item.isNew) {
          // Create new job record
          const createdItem = await this.EquipmentService.createCalibration(
              this.equipment_id,
              payload
          );
          if (createdItem) {
            const index = this.localJobHistory.findIndex((i) => i.id === item.id);
            if (index !== -1) {
              this.$set(this.localJobHistory, index, {
                ...createdItem,
                isNew: false,
                id: item.id, // Preserve local ID for tracking
              });
            }
          }
        } else {
          // Update existing job record using calibration_id
          await this.EquipmentService.updateCalibration(
              this.equipment_id,
              item.calibration_id,
              payload
          );

          const index = this.localJobHistory.findIndex((i) => i.id === item.id);
          if (index !== -1) {
            // Approach 1: Using Vue.set
            this.$set(this.localJobHistory, index, {
              ...this.localJobHistory[index],
              ...payload, // Merge updated data
              isNew: false, // Ensure isNew is false for updated records
            });
          }
        }

        this.notifySuccess("Job record saved successfully.");
        this.editableRowId = null; // Exit edit mode
      } catch (error) {
        this.notifyError("Failed to save job record.");
        console.error(error);
      }
    },
    async deleteRow(rowId) {

      const confirmed = await this.confirmAction({
        message: "Are you sure you want to delete the selected job record?",
      });

      // Only proceed if the action is confirmed
      if (!confirmed) {
        return;
      }

      const itemIndex = this.localJobHistory.findIndex((item) => item.id === rowId);
      const item = this.localJobHistory[itemIndex];

      if (!item.calibration_id) {
        // If no calibration_id (unsaved job), remove it locally
        this.localJobHistory.splice(itemIndex, 1);
        return;
      }

      try {
        // Delete existing job record using calibration_id
        await this.EquipmentService.deleteCalibration(this.equipment_id, item.calibration_id);
        this.localJobHistory.splice(itemIndex, 1);
        this.notifySuccess("Job record deleted successfully.");
        this.checkAndAddInitialJob();
      } catch (error) {
        this.notifyError("Failed to delete job record.");
        console.error(error);
      }
    },
    cancelEdit(item) {
      if (item.isNew) {
        this.localJobHistory = this.localJobHistory.filter((i) => i.id !== item.id);
      } else {
        const originalData = this.originalJobData[item.id];
        if (originalData) {
          const index = this.localJobHistory.findIndex((p) => p.id === item.id);
          this.localJobHistory.splice(index, 1, originalData);
        }
      }
      this.editableRowId = null; // Exit edit mode
    },
    isLastRow(rowId) {
      return this.localJobHistory[this.localJobHistory.length - 1].id === rowId;
    },
    generateGUID() {
      return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = (Math.random() * 16) | 0;
        return c === "x" ? r.toString(16) : ((r & 0x3) | 0x8).toString(16);
      });
    },
    convertToUnix(date) {
      return date ? moment(date, "DD-MM-YYYY").unix() : null;
    },
    formatTimestamp(unixTimestamp) {
      return moment.unix(unixTimestamp).format("DD-MM-YYYY");
    },
    checkAndAddInitialJob() {
      if (this.localJobHistory.length === 0) {
        this.addJobRecord();
      }
    },
  },
};
</script>
